<template>
    <page-header-wrapper>
        <a-card>
            <div class="table-page-search-wrapper">
                <a-form layout="inline" class="table-head-ground">
                    <div class="table-layer">
                        <a-form-item label="" class="table-head-layout" style="max-width:350px;min-width:300px">
                            <a-range-picker @change="onChange" :value="createdDate" :showTime="{
                                hideDisabledOptions: true,
                                defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                            }" format="YYYY-MM-DD HH:mm:ss" :disabled-date="disabledDate">
                                <a-icon slot="suffixIcon" type="sync" />
                            </a-range-picker>
                        </a-form-item>
                        <a-form-item label="" class="table-head-layout">
                            <a-input v-model="searchData.orderNo" placeholder="请输入订单号" />
                        </a-form-item>
                        <!-- payMode -->
                        <a-form-item label="" class="table-head-layout" :wrapper-col="{ span: 16 }">
                            <a-select v-model="searchData.payMode" placeholder="支付模式" :allowClear="true">
                                <a-select-option v-for="(item) in payModeList" :key="item.value" :value="item.value"
                                    :label="item.label">{{ item.label }}</a-select-option>
                            </a-select>
                        </a-form-item>
                        <span class="table-page-search-submitButtons">
                            <a-button type="primary" icon="search" @click="queryFunc"
                                :loading="btnLoading">搜索</a-button>
                            <a-button style="margin-left: 8px" icon="reload" @click="reset">重置</a-button>
                            <a-button style="margin-left: 8px" type="primary" icon="search" @click="exportfunc">导出</a-button>
                        </span>
                    </div>
                </a-form>
                <div style="display: flex;margin-bottom: 10px;align-items: center;justify-content: space-between;">
                    <a-button type="primary" @click="showStatistics = !showStatistics">
                        <a-icon type="bar-chart" /> {{ showStatistics ? '关闭统计' : '显示统计' }}
                    </a-button>
                </div>
                <!--统计信息-->
                <div style="margin-bottom:20px">
                    <div class="statistics-view" v-if="showStatistics" :searchData="searchData">
                        <div style="flex: 1;justify-content: center;align-items: center;">
                            <div class="statistics-item-view">
                                <span>交易金额</span>
                                <span class="amount-view">{{ orderInfo.amount | curreny }}<span class="text-view">元</span></span>
                            </div>
                        </div>
                        <div style="flex: 1;justify-content: center;align-items: center;">
                            <div class="statistics-item-view">
                                <span>分账笔数</span>
                                <span class="amount-black-view">{{ orderInfo.divCount | curreny }}<span
                                        class="text-view">笔</span></span>
                            </div>
                        </div>
                        <div style="flex: 1;justify-content: center;align-items: center;">
                            <div class="statistics-item-view">
                                <span>分账金额</span>
                                <span class="amount-view">{{ orderInfo.divideAmount | curreny }}<span
                                        class="text-view">元</span></span>
                            </div>
                        </div>
                        <div style="flex: 1;justify-content: center;align-items: center;">
                            <div class="statistics-item-view">
                                <span>手续费金额</span>
                                <span class="amount-view">{{ orderInfo.feeAmount | curreny }}<span
                                        class="text-view">元</span></span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- 列表渲染 -->
            <JeepayTable @btnLoadClose="btnLoading = false" ref="infoTable" :initData="true" :closable="true"
                :searchData="searchData" :reqTableDataFunc="reqTableDataFunc" :tableColumns="tableColumns"
                rowKey="refundOrderId" :scrollX="1300">

            </JeepayTable>
        </a-card>
    </page-header-wrapper>
</template>
<script>
import JeepayTextUp from '@/components/JeepayTextUp/JeepayTextUp' // 文字上移组件
import JeepayTable from '@/components/JeepayTable/JeepayTable'
import JeepayTableColumns from '@/components/JeepayTable/JeepayTableColumns'
import { req, API_URL_RECONCILIATION_DIVLIST, API_URL_RECONCILIATION_DIVCOUNT } from '@/api/manage'
import moment from 'moment'
import update from './update'
import request from '@/http/request'
const tableColumns = [
    { key: 'payId', title: '支付对象id', dataIndex: 'payId', width: '180px',ellipsis: true },
    { key: 'orderNo', title: '订单号', dataIndex: "orderNo", width: '180px',ellipsis: true },
    { key: 'amount', title: '交易金额', dataIndex: 'amount', width: '180px' ,customRender : (amount) => {return amount ? parseFloat(amount).toFixed(2) : "0.00"}},
    { key: 'divideAmount', title: '分账金额', dataIndex: 'divideAmount', width: '180px' ,customRender : (amount) => {return amount ? parseFloat(amount).toFixed(2) : "0.00"}},
    { key: 'tradeTime', title: '交易完成时间', dataIndex: 'tradeTime', width: '180px' },
    { key: 'channelPayOrderNo', title: '渠道订单号', dataIndex: 'channelPayOrderNo', width: '260px' },
    { key: 'payMode', title: '支付模式', dataIndex: "payMode", width: '180px' },
    { key: 'payConfirmId', dataIndex: 'payConfirmId', title: '支付确认对象id', width: '180px',ellipsis: true },
    { key: 'divideUser', dataIndex: 'divideUser', title: '分账用户', width: '180px' },
    { key: 'feePayer', dataIndex: 'feePayer', title: '手续费承担方', width: '180px' },
    { key: 'feeAmount', dataIndex: 'feeAmount', title: '手续费金额', width: '180px' ,customRender : (amount) => {return amount ? parseFloat(amount).toFixed(2) : "0.00"}}]
export default {
    name: 'ReconciliationAdapayDiv',
    mixins:[update],
    components: {
        JeepayTextUp,
        JeepayTable,
        JeepayTableColumns
    },
    filters:{
        curreny:function(data){
            data = data ? parseFloat(data).toFixed(2) : "0.00";
            return data;
        }
    },
    data() {
        return {
            btnLoading: false,
            tableColumns: tableColumns,
            searchData: {
                createdStart: '', // 选择开始时间
                createdEnd: '', // 选择结束时间
                orderNo: "",
                payMode: undefined
            },
            createdDate: [],
            payModeList: [
                {
                    value: '实时',
                    label: '实时'
                },
                {
                    value: '延时',
                    label: '延时'
                }
            ],
            orderInfo: {},
            showStatistics: false,
        }
    },
    mounted() {
        this.reqOrderCount()
    },
    methods: {
        // 请求统计接口数据
        reqOrderCount() {
            req.list(API_URL_RECONCILIATION_DIVCOUNT, this.searchData).then(res => {
                this.orderInfo = res;
            })
        },

        queryFunc() {
            this.btnLoading = true
            this.reqOrderCount()
            this.$refs.infoTable.refTable(true)
        },
        // 请求table接口数据
        reqTableDataFunc: (params) => {
            return req.list(API_URL_RECONCILIATION_DIVLIST, params)
        },
        searchFunc() { // 点击【查询】按钮点击事件
            this.btnLoading = true;
            this.reqOrderCount()
            this.$refs.infoTable.refTable(true)
        },
        moment,
        onChange(date, dateString) {
            this.createdDate = dateString;
            this.searchData.createdStart = dateString[0] // 开始时间
            this.searchData.createdEnd = dateString[1] // 结束时间
        },
        disabledDate(current) { // 今日之后日期不可选
            return current && current > moment().endOf('day')
        },
        reset() {
            this.searchData = {
                createdStart: '', // 选择开始时间
                createdEnd: '', // 选择结束时间
                orderNo: "",
                payMode: undefined
            }
            this.createdDate = [];
            setTimeout(() => { this.$refs.infoTable.refTable(true) }, 1000)
        },
        exportfunc() {
            request.request({
                url: '/mch/reconciliation/adapay/data/asynExportDivList',
                method: 'post',
                data: this.searchData,
            }).then(res => {
                this.$message.success(res);
            })
        }
    }
}
</script>
<style scoped lang="less">
.statistics-view {
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    flex-direction: row;
    display: flex;
    margin-top: 20px;
    padding: 30px 0px 30px 0px;

}

.statistics-item-view {
    flex-direction: column;
    display: flex;
    text-align: start;
    align-items: center;
}

.amount-view {
    font-size: 20px;
    color: #5485f8;
    font-weight: bold;
    margin-top: 10px;
}

.amount-black-view {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    margin-top: 10px;
}

.amount-h-view {
    font-size: 20px;
    color: #dbaf0e;
    font-weight: bold;
    margin-top: 10px;
}

.text-view {
    font-size: 14px;
    font-weight: normal;
}

.mx-text {
    font-size: 14px;
    font-weight: normal;
    color: #55a2ff;
    padding-left: 10px;
}
::v-deep .ant-upload-list{
    width: 300px !important;
}
</style>