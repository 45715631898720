import appConfig from '@/config/appConfig'
import storage from '@/utils/jeepayStorageWrapper'
function getHeaders() {
    const headers = {}
    headers[appConfig.ACCESS_TOKEN_NAME] = storage.getToken() // token
    return headers
}

export default {
    data() {
        return {
            StatusList: [
                {
                    value: 'S',
                    label: '成功'
                },
                {
                    value: 'F',
                    label: '失败'
                }
            ],
            fileList: [],
            headers: getHeaders(),
            action: process.env.VUE_APP_API_BASE_URL + '/mch/file/upload',
        }
    },
    methods: {
        handleChange(info) {
            if (info.file.status === 'done') {
                this.$message.success(info.file.response.data);
                this.fileList = [];
            } else if (info.file.status === 'error') {
                this.fileList = []
                this.$message.error('上传失败,请稍后重试');
            }
        },
    }
}